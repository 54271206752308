import 'jquery';
import 'select2/dist/js/select2.full';
import 'bootstrap/js/dist/modal';

/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */
import sliderInit from './modules/sliders';
import accordions from './modules/accordions';
import tabs from './modules/tabs';
import select from './modules/select';
import headerFunctionsInit from './modules/header-functions';
import equalHeighInit from './modules/equal-height';
import googleTagsInit from './modules/google-tags';
import contactApiInit from './modules/contact';

/*
 * SCSS
 */

import '../scss/style.scss';

/*
 * Init JS Code
 */

sliderInit();
accordions();
tabs();
select();
headerFunctionsInit();
equalHeighInit();
googleTagsInit();
contactApiInit();
